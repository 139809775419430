export default {
  canary: "#FAEB3F",
  caribbean: "#22E9A5",
  cerulean: "#00A3E6",
  cobalt: "#0047AB",
  darkCanary: "#F3BB1C",
  darkGray: "#8180a2",
  gray: "#D0D0D0",
  lightGray: "#E9E9F1",
  lightBlue: "#eff3fc",
  mint: "#b7f7e0",
  offWhite: "#F6F5FB",
  white: "#FFFFFF",
  red: "#E12021",
  black: "#343941",
  grey90: "#4A5157",
  grey70: "#6E7479",
  blue700: "#1B46A5"
};

