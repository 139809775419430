export const columnSize = {
  small: 540,
  medium: 720,
  large: 960
};

export const windowSize = {
  small: 576,
  medium: 767,
  large: 992
};
