import { createGlobalStyle } from "styled-components";
import colors from "../utils/colors";

export default createGlobalStyle`
  body {
    background: ${colors.cobalt};
    font-family: Avenir, 'Nunito Sans', helvetica, sans-serif;
    margin: 0;
  }

  h1 {
    color: ${colors.cobalt};
  }

  h2 {
    color: ${colors.cobalt};
  }

  a {
    color: ${colors.cobalt};
    text-decoration: none;
  }
`;
